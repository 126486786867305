.Home {
  background: url("../assets/stars.svg");
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
}

.about {
  background: url("../assets/Stars2.png");
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
}

.features {
  background: url("../assets/stars.png");
  background-color: black;
  background-size: contain;
  
}

.footer {
  background: url("../assets/Stars2.png");
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
}
